import { ScriptLoader } from "../common/script-loader";

export class FaceBookAuthenticator {
    /**
     * Meta tag that contains the public Google client ID
     * @param {string} clientId
     */
    constructor(clientId) {
        this.scriptLoader = new ScriptLoader(['https://connect.facebook.net/en_US/sdk.js'])
        this.clientId = clientId;
        this.scriptPromises = [];

        this.validate();

        this.loadDependencies()
            .then(this.iniAuthorization.bind(this))
    }

    /**
     * Load needed scripts for facebook implementation 
     * 
     * @return self
     */
    loadDependencies() {
        return this.scriptLoader.load();
    }

    /**
     * Load google API for authentication
     * Try to retrieve the user information if the user is signed in 
     * promp the google sign in iframe if the user is not authenticated
     * 
     * @return Promise
     */
    iniAuthorization() {
        return new Promise((resolve, reject) => {
            try {
                FB.init({
                    appId: this.clientId,
                    xfbml: true,
                    version: 'v9.0'
                });
                this.authInstance = FB;
                resolve(FB)
            } catch (error) {
                reject(error);
            }
        })
    }

    /**
     *  check if the page have needed information to bootstrap the google API
     */
    validate() {
        if (this.clientId == null || this.clientId == undefined || this.clientId == "") {
            throw Error(4033, "Client ID is incorrect or it was not passed");
        }
    }

    login() {
        return new Promise((resolve, reject) => {
            FB.login(response => {
                if (response.authResponse) {
                    let oauthData = { ...response.authResponse };
                    FB.api('/me?fields=first_name,last_name,id,email', (userData) => {
                        resolve({ ...oauthData, ...userData })
                    });
                } else {
                    reject("Error")
                }
            }, { scope: 'email,public_profile' });
        })

    }

}