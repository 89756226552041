import { GmailAuthenticator } from "../authenticators/gmail.authenticator.js";
import { AlertComponent } from "../common/wa-alert.js";
import { WALoader } from "../common/wa-loader.js";
import { trans, scrollTo, getElement } from "../common/helpers.js";
import { WAToolTip } from "../common/wa-tool-tip";
import { PageInteractor } from "../common/page-interactor.js";

import { FaceBookAuthenticator } from "../authenticators/facebook.authenticator.js";

document.addEventListener('waConfigurationsLoaded', () => {
    try {
        new WAToolTip(
            "#claim_account_tooltip",
            trans('claimAccount.tooltip'),
            true
        );
    } catch (e) {
        console.log(e);
    }

    try {
        if (loginErrors) {
            const pageInteractor = new PageInteractor();
            pageInteractor.forSection('expand');
            pageInteractor.simulate('click', () => {
                scrollTo(`[data-wa-component="alert"]`)
            });
        }
    } catch (e) {

    }
    let alertComponent = null;
    try {
        if (window.google_login_client_id && window.google_login_client_id !== '') {
            document.addEventListener('waGAuth', (ev) => {
                const auth = ev.detail;
                if (auth.error) {
                    (new WALoader()).hide();
                    if (alertComponent === null) {
                        alertComponent = new AlertComponent({
                            classes: ['col-centered', 'create-account-alert', 'alert-holder', 'visible']
                        });
                    }

                    alertComponent.text = trans('authentication.signIn.google.error');
                    alertComponent.render();
                    return;
                }

                $('#login-form').append(
                    `<input type='hidden' name='oauth_id' value="${auth.authToken}">
            <input type='hidden' name='userData[email]' value="${auth.email || ''}">
            <input type='hidden' name='userData[firstName]' value="${auth.firstName || ''}">
            <input type='hidden' name='userData[lastName]' value="${auth.lastName || ''}">`
                )
                // change the request route
                $('#login-form').attr('action', '/google-login')

                // remove global validation
                $('#login-form input.form-element').each((key, el) => {
                    el.classList.add('novalidate')
                })
                const gLoginForm = getElement('#login-form');
                // Send the request to the right authentication route
                gLoginForm && gLoginForm.submit()
            })

            const gmailAuth = new GmailAuthenticator(window.google_login_client_id);
            $('#gmailLogin').on('click', () => {
                gmailAuth.extractUserInformation()
            });

        }

    } catch (error) {
        console.log(error);
        // Log errors if needed
    }
    const external_referrer = window['external_referrer'] || null
    try {
        if (window.facebook_login_client_id && window.facebook_login_client_id !== '') {
            const fbInstance = new FaceBookAuthenticator(window['facebook_login_client_id'])

            document.getElementById('fbLogin').addEventListener('click', (e) => {
                e.preventDefault()
                e.stopPropagation();
                (new WALoader()).show();
                fbInstance.login().then(userData => {
                    $('#login-form').attr('action', external_referrer ? `/facebook-login?redirect_uri=${external_referrer}` : '/facebook-login')
                    $('#login-form input.form-element').each((key, el) => {
                        el.classList.add('novalidate')
                    })
                    $('#login-form').append(
                        `<input type='hidden' name='oauth_id' value="${userData.accessToken}">`);
                    $('#login-form').submit();
                }).catch((e) => {
                    (new WALoader()).hide();
                    if (alertComponent === null) {
                        alertComponent = new AlertComponent({
                            classes: ['col-centered', 'create-account-alert', 'alert-holder', 'visible']
                        });
                    }

                    alertComponent.text = trans('authentication.signIn.facebook.error');
                    alertComponent.render();
                })
            })
        }
    } catch (error) {

    }

})
