import { trans } from "../common/helpers";

export class GmailAuthenticator {
    /**
     * Meta tag that contains the public Google client ID
     * @param {string} clientId
     */
    constructor(clientId) {
        this.scriptDependencies = [
            'https://accounts.google.com/gsi/client'
        ];
        this.clientId = clientId;
        this.scriptPromises = [];

        this.validate();

        this.loadDependencies()
            .then(this.iniAuthorization.bind(this))
            .then((client) => this.authInstance = client)
    }

    /**
     * Load needed scripts for gmail implementation 
     * Gmail Api
     * 
     * @return self
     */
    loadDependencies() {
        return new Promise((resolve, reject) => {
            let script;

            this.scriptDependencies.forEach(depLink => {
                script = document.createElement('script');
                script.src = depLink;
                document.head.appendChild(script);
                this.resolveScript(script);
            });

            Promise.all(this.scriptPromises).then((statuses) => {
                resolve(true);
            }).catch(statuses => {
                reject('Script could not be loaded.');
            });
        })
    }

    /**
     * Load google API for authentication
     * Try to retrieve the user information if the user is signed in 
     * prompt the google sign in iframe if the user is not authenticated
     * 
     * @return Promise
     */
    iniAuthorization() {
        return new Promise((resolve, reject) => {
            try {
                const client = google.accounts.oauth2.initTokenClient({
                    client_id: this.clientId,
                    scope:'profile email',
                    callback: this._gAuthResponseHandle,
                  });

                resolve(client)
            } catch (error) {
                reject(error);
            }
        })
    }

    /**
     *  check if the page have needed information to bootstrap the google API
     */
    validate() {
        if (this.clientId == null || this.clientId == undefined || this.clientId == "") {
            throw Error(4033, "Client ID is incorrect or it was not passed");
        }
    }

    /**
     * Adds promise that will be resolved or rejected after given script is loaded
     * all scripts have to be successuffully loaded to continue with execution
     * 
     * @param {DOMElement} script 
     */
    resolveScript(script) {
        this.scriptPromises.push(new Promise((resolve, reject) => {
            script.onload = () => resolve(true);
            script.onerror = () => reject(false);
        }));
    }

    /**
     * 
     * @param {Object} response {credential:string, select_by:boolean, clientId:string}
     * @returns Promise<user>
     */
    _gAuthResponseHandle(response) {
        try {
            // const [userData, err] = extractJWTData(response.credential);
            document.dispatchEvent(new CustomEvent('waGAuth', {
                detail: {
                    'authToken': response.access_token,
                    'email': "",
                    'firstName': "",
                    'lastName': "",
                }
            }));
        } catch (error) {
            document.dispatchEvent(new CustomEvent('waGAuth', {
                detail: {
                    error: trans('general.errors.generic')
                }
            }));
        }
    }

    /**
     *  Build up the user information object after authentication is successful
     */
    extractUserInformation() {
        return new Promise((resolve, reject) => {
            try {
                this.authInstance.requestAccessToken();
                // google.accounts.id.prompt();
            } catch (error) {
                reject(error);
            }
        })
    }



}