import { getElement, deleteElement } from "../common/helpers.js"
export class WAToolTip {
    constructor(container, content, hover) {
        this.el = getElement(container);
        this.content = content;
        this.triggerEl = this.el.querySelector('[data-tooltip-trigger]')
        this.isOpen = false;
        this.__attachHandlers(hover);
    }
    
    __attachHandlers(hover) {
        if(hover){
            this.triggerEl.addEventListener("mouseenter", e => {
                if (!this.isOpen) {
                    const elem = this.__generateContent(true);
                    this.triggerEl.addEventListener('mouseout', e=>{
                        deleteElement(elem);
                        this.isOpen = false;
                    })
                    this.el.append(elem);
                    this.isOpen = true;
                }
            })
            return;
        }
        this.triggerEl.addEventListener("click", e => {
            if (!this.isOpen) {
                const elem = this.__generateContent();
                const close = elem.querySelector("[data-wa-tooltip-close]");
                close.addEventListener('click', ev => {
                    deleteElement(elem);
                    this.isOpen = false;
                })
                this.el.append(elem);
                this.isOpen = true;
            }
        })

    }

    __generateContent(withoutClose) {
        const cont = document.createElement('span');

        cont.innerHTML = `
                <div class="wa-font wa-tooltip text-align-left ">
                    ${withoutClose? '' : '<span class="wa-icon-close-font cursor-pointer wa-tooltip-close" data-wa-tooltip-close="true"></span>'}
                    ${this.content}
                </div>
            `;
        return cont.firstElementChild;
    }
}
