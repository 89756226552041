import { getElement } from '../common/helpers.js'
export class AlertComponent {
    constructor({classes, target:target = null}) {
        this._getContainer(target);
        this._customClasses = classes;
        this._text = null;
        this._title = null;
    }

    /**
     * Add class to the list of custom classes
     * 
     * @param {String} className 
     */
    appendClass(className){
        this._customClasses = [...this._customClasses, className];

        return this;
    }
    
    /**
     * @param {String} className 
     */
    removeClass(className){
        console.log(className);
        
        return this;
    }

    /**
     * Add class to the list of custom classes
     * 
     * @param {String} className 
     */
    removeClass(className) {
        this._customClasses = this._customClasses.filter(currentClass=>{
            return className!=currentClass;
        })

        return this;
    }

    set text(text = null) {
        this._text = text;
    }
    get text() {
        return this._text;
    }
    set title(title = null) {
        this._title = title;
    }
    get title() {
        return this._title;
    }


    render() {
        if (this.container === null) { throw Error(`Element ${selector} does not exist!`); }
        const containerEl = document.createElement('div');
        containerEl.innerHTML = this._buildComponent();
        this.container.innerHTML = "";
        this.container.appendChild(containerEl.firstChild);
    }

    _getContainer(target = null) {
        const selector = `${target !== null ? `[data-wa-source='${target}']` : ""}[data-wa-component="alert"]`;
        this.container = getElement(selector);
    }

    _buildComponent() {
        return `<div class="col-xs-12 ${this._buildComponentClasses()}">
            <div class=" alert alert-danger alert-dismissible" role="alert">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                ${ this.title ? `<p class="alert-title"><span class="bold">${this.title}</span></p>` : ''}
                ${ this.text ? `<p><span>${this.text}</span></p>` : ''}
            </div>
        </div>`
    }

    _buildComponentClasses() {
        let classesString = "";
        this._customClasses.map(className => {
            classesString += ` ${className}`;
        })
        return classesString;
    }
}